import { Payment, PaymentDetails } from '../types/paymentTypes';

export const isObjectEmpty = (object: {}) => !Object.keys(object).length;

export const convertToCurrency = (number: number | string) =>
  Number(number)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const extractPaymentType = (payment: Payment, key: string) =>
  payment.paymentDetails.filter((payment: PaymentDetails) => payment.key === key)[0];

export const urlToken = window.location.pathname.replace('/', '') || localStorage.getItem('access_token') || '';

export const urlPayment = window.location.search.match(/payment=-?[0-9]+/)?.[0].replace(/payment=/, '');

export const urlLanguage =
  window.location.search.match(/language=[a-z]+/)?.[0].replace(/language=/, '') ||
  localStorage.getItem('language') ||
  'en';

const renderDate = (date: Date | undefined) => {
  if (date) {
    date = new Date(date);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  } else return undefined;
};

export const empInfo_0 = (payment: Payment) => {
  if (typeof payment.emp === 'object')
    return {
      title: { en: '', ar: '' },
      values: [
        {
          name: { en: 'Employee Number', ar: 'رقم الموظف' },
          value: { en: payment.emp._id, ar: payment.emp._id },
        },
        {
          name: { en: 'Employee Name', ar: 'اسم الموظف' },
          value: payment.emp.name,
        },
        {
          name: { en: 'Hiring Date', ar: 'تاريخ التوظيف' },
          value: {
            en: renderDate(payment.emp.hiringDate),
            ar: renderDate(payment.emp.hiringDate),
          },
        },
        {
          name: { en: 'Account No.', ar: 'رقم الحساب' },
          value: { en: payment.bank.account, ar: payment.bank.account },
        },
        {
          name: { en: 'Mobile No.', ar: 'رقم المحمول' },
          value: { en: payment.emp.phone, ar: payment.emp.phone },
        },
      ],
    };
};

export const empInfo_1 = (payment: Payment) => {
  if (typeof payment.emp === 'object')
    return {
      title: { en: '', ar: '' },
      values: [
        { name: { en: 'Position', ar: 'منصب' }, value: payment.emp.job },
        {
          name: { en: 'Transfer Date', ar: 'تاريخ التحويل' },
          value: {
            en: renderDate(payment.emp.hiringDate),
            ar: renderDate(payment.emp.hiringDate),
          },
        },
        {
          name: { en: 'Assigned To', ar: 'العميل' },
          value: payment.emp.client.name,
        },
        {
          name: { en: 'Department', ar: 'قسم' },
          value: payment.emp.department,
        },
        {
          name: { en: 'National ID', ar: 'رقم البطاقة' },
          value: {
            en: payment.emp.identityNumber,
            ar: payment.emp.identityNumber,
          },
        },
      ],
    };
};
