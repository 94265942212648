import axios from "axios";

const baseURL = "https://api.ibsns.com/v1";
// const baseURL = 'http://localhost:5000/v1';
export const DB_SYNC = "https://dbsync.ibsns.com";
const HTTP = axios.create({
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
  baseURL,
});

HTTP.interceptors.response.use(
  (res) => Promise.resolve(res.data),
  (err) => Promise.reject(err)
);

HTTP.interceptors.request.use(async (req) => {
  req.headers["Accept-language"] = localStorage.getItem("language");
  return req;
});

export default HTTP;
