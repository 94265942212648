import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { convertToCurrency, isObjectEmpty } from '../../utils/helpers';

const ContentBox = ({ data, numbers }: any) => {
  const { language } = useContext(AppContext);
  return data && !isObjectEmpty(data) ? (
    <div className="box">
      <p className="header">{data.title[language]}</p>
      <div className="payments-box">
        {data.values.map((value: any) =>
          value.value ? (
            <div key={value.name[language]} className="payments-row">
              <p>{value.name[language]}</p>
              <p>{numbers ? convertToCurrency(value.value[language]) : value.value[language]}</p>
            </div>
          ) : null
        )}
      </div>
      {numbers && (
        <div className="payments-row total">
          <p>Total</p>
          <p>{convertToCurrency(data.total)}</p>
        </div>
      )}
    </div>
  ) : null;
};

export default ContentBox;
