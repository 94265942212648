import { Button as ButtonType } from './Button.types';
import './Button.scss';

const Button = (props: ButtonType) => {
  if (props.form)
    return (
      <button
        className={`button ${
          props.disabled ? 'button-downloading' : props.red ? 'bg' : props.transparent ? 'button-transparent' : ''
        }`}
        type="submit"
      >
        {props.text}
      </button>
    );
  return (
    <div
      className={`button ${
        props.disabled ? 'button-downloading' : props.red ? 'bg' : props.transparent ? 'button-transparent' : ''
      }`}
      onClick={props.onClick}
    >
      {props.text}
    </div>
  );
};

export default Button;
