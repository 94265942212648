import React, { useContext, useEffect, useState } from 'react';
import { verifyToken } from '../api/api_requests';
import { EmptyArgumentsVoidFunction } from '../types/functions';
import { urlLanguage, urlToken } from '../utils/helpers';

interface AppContextInterface {
  language: string;
  changeLanguage: () => void;
  user: any;
  setUser: <user extends unknown>(user: user) => void;
  authLoading: boolean;
}

export const AppContext = React.createContext<AppContextInterface>({
  language: 'en',
  changeLanguage: () => {},
  user: {},
  setUser: () => {},
  authLoading: true,
});

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [language, setLanguage] = useState(urlLanguage || localStorage.getItem('language') || 'en');
  const [user, setUser]: [any, any] = useState({});
  const [authLoading, setAuthLoading] = useState(true);

  const verify = () => {
    verifyToken(urlToken)
      .then((user: any) => {
        setAuthLoading(false);
        const emp = user.user.emp;
        setUser({
          ...emp,
          client: user.user.client,
          isVerified: user.user.isVerified,
        });
      })
      .catch(() => {
        setAuthLoading(false);
      });
  };

  // ? The URL Language is passed into the page through the "language" query param
  // ? If not given, it defaults to either the last saved language, or to 'en'
  const setDefaultLanguage: EmptyArgumentsVoidFunction = () => {
    localStorage.setItem('language', urlLanguage);
  };

  useEffect(() => {
    setDefaultLanguage();
    verify();
  }, []);

  const changeLanguage = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  return (
    <AppContext.Provider
      value={{
        language,
        changeLanguage,
        user,
        setUser,
        authLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
