import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

const translations: any = {
  en: {
    payment: 'Payment for',
    download: 'Download',
    language: 'عربي',
    total: 'Bank Net',
    additions: 'Additions',
    deductions: 'Deductions',
    bankNet: 'Bank Net',
    '404': `This payment is not available`,
    '401': `This payment is not available`,
    '500': 'The server is currently under maintenance',
    error: 'A problem has occurred, please try again later',
    signIn: 'Sign in',
    signInSubtitle: 'Enter your credentials below to continue',
    id: 'Phone, National ID or Passport Number',
    password: 'Password',
    register: 'Register',
    welcome: 'Welcome',
    welcomeSubtitle: 'Login to view your Payment Slip',
    signUp: 'Sign up',
    phone: 'Phone Number',
    confirmPassword: 'Confirm Password',
    signUpSubtitle: 'Enter your information to create an account',
    login: 'Login',
    submit: 'Submit',
    loading: 'Loading',
    enterOTP: 'Enter the OTP',
    enterOTPSubtitle: 'An SMS has been sent to you, please type the code that was provided.',
    logout: 'Logout',
  },
  ar: {
    payment: 'دفعة شهر',
    download: 'تحميل',
    language: 'English',
    total: 'صافي التحويل',
    additions: 'الاضافات',
    deductions: 'الخصومات',
    bankNet: 'إجمالي',
    '404': `بياناتك غير صحيحة`,
    '401': `بياناتك غير صحيحة`,
    '500': 'الخادم حاليا تحت الصيانة',
    error: 'حدثت مشكلة ، يرجى المحاولة مرة أخرى في وقت لاحق',
    id: 'رقم الهاتف، رقم البطاقة أو رقم الباسبور',
    password: 'كلمه السر',
    welcome: 'أهلا بك',
    welcomeSubtitle: 'سجل دخولك لعرض الفاتورة',
    signUp: 'انشئ حساب',
    phone: 'الهاتف',
    confirmPassword: 'تأكيد كلمة المرور',
    signUpSubtitle: 'أدخل المعلومات الخاصة بك لإنشاء حساب',
    signIn: 'تسجيل الدخول',
    signInSubtitle: 'أدخل بياناتك للمتابعة',
    submit: 'تسجيل الدخول',
    register: 'انشئ حساب',
    loading: 'جاري التحميل',
    enterOTP: 'أدخل كلمة المرور',
    enterOTPSubtitle: 'تم إرسال رسالة إليك ، يرجى كتابة الرمز الذي تم توفيره.',
    logout: 'خروج',
  },
};

export const useLanguageText = (text: string) => {
  const { language } = useContext(AppContext);
  return translations[language][text];
};
