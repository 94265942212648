import HTTP, { DB_SYNC } from "../utils/axios";
import { urlToken } from "../utils/helpers";

export const verifyToken = async (token: string) =>
  HTTP.post("/verify-access-token", { token }).then((resp) => {
    HTTP.defaults.headers.common["authorization"] = token;
    localStorage.setItem("access_token", token);
    return resp;
  });

export const login = async (data: {
  identityNumber: string;
  password: string;
}) =>
  HTTP.post("/login", data).then((resp: any) => {
    HTTP.defaults.headers.common["authorization"] = resp.access_token;
    localStorage.setItem("access_token", resp.access_token);
    return resp;
  });

export const register = async (data: {
  identityNumber: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
}) => HTTP.post("/register", data);

export const getPayment = async (paymentId: string | undefined) =>
  HTTP.get<any, any>(`/user/payments/${paymentId}`);

export const sendOTP = async () => HTTP.post("/verify-phone");

export const verifyOTP = async (code: string) =>
  HTTP.post("/check-verification-code", { code });

export const downloadPDF = async (payment: any, language: String) =>
  HTTP.post(
    "/db/info/payment",
    {
      id: payment,
      language,
    },
    {
      headers: {
        authorization: urlToken,
      },
      responseType: "blob",
      baseURL: DB_SYNC,
    }
  );
