import './pages/Main/Main.scss';
import Main from './pages/Main/Main';
import Loading from './components/Loading/Loading';
import Login from './pages/Login/Login';
import { useAppContext } from './context/AppContext';
import './App.scss';

function App() {
  const { user, authLoading } = useAppContext();

  if (authLoading) return <Loading />;

  if (!Object.keys(user).length || !user.isVerified) return <Login />;

  return <Main />;
}

export default App;
