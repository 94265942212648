import React, { useEffect, useState } from 'react';
import { login, register, sendOTP, verifyOTP } from '../../api/api_requests';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { useAppContext } from '../../context/AppContext';
import { useLanguageText } from '../../hooks/language';
import HTTP from '../../utils/axios';
import './Login.scss';
import { LoginFormElement, OTPFormElement, RegisterFormElement } from './Login.types';
const iconUrl = 'images/ibs_logo.png';

const Login = () => {
  const { language, user } = useAppContext();

  // * True means login, false means register
  const [login, setLogin] = useState(true);

  const changeState = () => setLogin((login) => !login);

  return (
    <div className={`login ${language}`}>
      <div className="login-subcontainer-1">
        {user?.isVerified === false ? (
          <OTPForm />
        ) : login ? (
          <LoginForm changeState={changeState} />
        ) : (
          <RegisterForm changeState={changeState} />
        )}
      </div>
      <div className="login-subcontainer-2">
        <p>{useLanguageText('welcome')}</p>
        <p>{useLanguageText('welcomeSubtitle')}</p>
      </div>
    </div>
  );
};

const LoginForm = ({ changeState }: any) => {
  const { setUser, changeLanguage } = useAppContext();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleSubmit = (form: React.FormEvent<LoginFormElement>) => {
    form.preventDefault();
    const data = {
      identityNumber: form.currentTarget.elements.id.value,
      password: form.currentTarget.elements.password.value,
    };

    if (data.identityNumber.length < 11 || !data.password) return;
    setError('');
    setLoading(true);

    login(data)
      .then((resp: any) => {
        setLoading(false);
        const emp = resp.data.user.emp;
        setUser({
          ...emp,
          client: resp.data.user.client,
          isVerified: resp.data.user.isVerified,
        });
      })
      .catch((err) => {
        console.log(err.response.data.error);
        setError(err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <div className="login-text-container">
      <img alt="icon" className={`login-logo ${loading && 'login-animation'}`} src={iconUrl} />
      <p>{useLanguageText('signIn')}</p>
      <p>{useLanguageText('signInSubtitle')}</p>
      <p className="error">{error}</p>
      <form onSubmit={handleSubmit}>
        <Input title={'id'} />
        <Input password title={'password'} />
        <Button red form text={useLanguageText('submit')} />
        <Button onClick={changeState} transparent text={useLanguageText('register')} />
        <Button onClick={changeLanguage} text={useLanguageText('language')} />
      </form>
    </div>
  );
};

const RegisterForm = ({ changeState }: any) => {
  const { setUser, changeLanguage } = useAppContext();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmit = (form: React.FormEvent<RegisterFormElement>) => {
    form.preventDefault();
    const data = {
      identityNumber: form.currentTarget.elements.id.value,
      phone: form.currentTarget.elements.phone.value,
      password: form.currentTarget.elements.password.value,
      passwordConfirmation: form.currentTarget.elements.confirmPassword.value,
    };

    if (data.identityNumber.length < 11 || !data.password) return;
    setError('');
    setLoading(true);

    register(data)
      .then((resp: any) => {
        setLoading(false);
        const emp = resp.data.user.emp;
        localStorage.setItem('access_token', resp.access_token);
        HTTP.defaults.headers.common['authorization'] = resp.access_token;
        setUser({ ...emp, client: resp.data.user.client, isVerified: false });
      })
      .catch((err) => {
        const data = err.response.data;
        if (data.error) {
          setError(err.response.data.error);
        } else if (data.errors) {
          setErrors(
            data.errors.reduce((arr: any, err: any) => {
              arr[err.param === 'identityNumber' ? 0 : err.param === 'phone' ? 1 : err.param === 'password' ? 2 : 3] =
                err.msg;
              return arr;
            }, [])
          );
        }
        setLoading(false);
      });
  };

  return (
    <div className="login-text-container">
      <img alt="icon" className={`login-logo ${loading && 'login-animation'}`} src={iconUrl} />
      <p>{useLanguageText('signUp')}</p>
      <p>{useLanguageText('signUpSubtitle')}</p>
      <p className="error">{error}</p>
      <form onSubmit={handleSubmit}>
        <Input error={errors[0]} title={'id'} />
        <Input error={errors[1]} title={'phone'} />
        <Input error={errors[2]} password title={'password'} />
        <Input error={errors[3]} password title={'confirmPassword'} />
        <Button red form text={useLanguageText('register')} style={{ marginBottom: '15px' }} />
        <Button onClick={changeState} transparent text={useLanguageText('signIn')} />
        <Button onClick={changeLanguage} text={useLanguageText('language')} />
      </form>
    </div>
  );
};

const OTPForm = ({ changeState }: any) => {
  const { setUser, changeLanguage } = useAppContext();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sendOTP().catch((err) => {
      setError(err.response.data.error);
    });
  }, []);

  const handleSubmit = (form: React.FormEvent<OTPFormElement>) => {
    form.preventDefault();
    const code = form.currentTarget.elements.otp.value;

    if (code.length < 4) return;
    setError('');
    setLoading(true);

    verifyOTP(code)
      .then((resp: any) => {
        console.log(resp);
        setLoading(false);
        setUser((user: any) => ({ ...user, isVerified: true }));
      })
      .catch((err) => {
        console.log(err.response);
        setError(err.response.data.error);
        setLoading(false);
      });
  };

  const logout = () => {
    setUser({});
    localStorage.removeItem('access_token');
  };

  return (
    <div className="login-text-container">
      <img alt="icon" className={`login-logo ${loading && 'login-animation'}`} src={iconUrl} />
      <p>{useLanguageText('enterOTP')}</p>
      <p>{useLanguageText('enterOTPSubtitle')}</p>
      <p className="error">{error}</p>
      <form onSubmit={handleSubmit}>
        <Input title={'otp'} />
        <Button red form text={useLanguageText('submit')} />
        <Button onClick={logout} transparent text={useLanguageText('logout')} />
        <Button onClick={changeLanguage} text={useLanguageText('language')} />
      </form>
    </div>
  );
};

export default Login;
