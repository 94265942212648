import { getPayment } from '../../api/api_requests';
import { Payment } from '../../types/paymentTypes';
import { urlPayment } from '../../utils/helpers';

export const fetchData: any = async () =>
  getPayment(urlPayment).then((resp: { payment: Payment }) => {
    let payment = resp.payment;
    const info = payment.paymentDetails.filter((detail) => detail.key === 'info')[0];
    const duplicates = ['Invoice No.', 'Bank Name', 'Account No.', 'E-Mail'];
    if (info) {
      info.values = info.values.filter((value) => !duplicates.includes(value.name.en));
      payment.paymentDetails = payment.paymentDetails.map((detail) => {
        if (detail.key === 'info') return info;
        return detail;
      });
    }
    return payment;
  });
