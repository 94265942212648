import { useLanguageText } from '../../hooks/language';
import './Input.scss';

const Input = ({ title, password, error }: { title: string; password?: boolean; error?: string }) => {
  return (
    <div className="input">
      <div style={{ marginBottom: '0.5vh' }}>
        <p className="input-title">{useLanguageText(title)}</p>
        <p className="input-error">{error}</p>
      </div>
      <input type={password ? 'password' : undefined} id={title} className="input-box" />
    </div>
  );
};

export default Input;
