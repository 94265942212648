import { useContext, useState } from 'react';
import { downloadPDF } from '../../api/api_requests';
import { AppContext } from '../../context/AppContext';
import { useLanguageText } from '../../hooks/language';
import { EmptyArgumentsVoidFunction } from '../../types/functions';
import Button from '../Button/Button';
const download = require('downloadjs');

const ButtonsContainer = ({ payment }: any) => {
  console.log(payment);
  const { changeLanguage, language } = useContext(AppContext);
  const [downloading, setDownloading] = useState(false);

  const downloadHandler: EmptyArgumentsVoidFunction = () => {
    if (downloading) return;
    setDownloading(true);
    const fileName = `IBS ${language === 'ar' ? 'دفعة' : 'Payment'} ${payment.month?.[language] || payment._id}.pdf`;

    downloadPDF(payment._id, language)
      .then((pdf) => {
        download(pdf, fileName);
        setDownloading(false);
      })
      .catch((err) => console.error(err.response));
  };

  const [downloadText, languageText] = [useLanguageText('download'), useLanguageText('language')];
  return (
    <div className="right-container">
      <Button disabled={downloading} text={!downloading ? downloadText : '...'} onClick={downloadHandler} red />
      <Button text={languageText} onClick={changeLanguage} />
    </div>
  );
};

export default ButtonsContainer;
