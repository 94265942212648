import { useContext, useEffect, useState } from 'react';
import { extractPaymentType, empInfo_0, empInfo_1 } from '../../utils/helpers';

import SplitContentBox from '../../components/SplitContentBox/SplitContentBox';
import ContentBox from '../../components/ContentBox/ContentBox';
import TotalBox from '../../components/TotalBox/TotalBox';
import ButtonsContainer from '../../components/ButtonsContainer/ButtonsContainer';

import './Main.scss';

import { AppContext, useAppContext } from '../../context/AppContext';
import { useLanguageText } from '../../hooks/language';
import Loading from '../../components/Loading/Loading';
import { fetchData } from './Main.functions';
import Error from '../../components/Error/Error';

const iconUrl = 'images/ibs_logo.png';

const Main = () => {
  const [payment, setPayment]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const { user: emp } = useAppContext();
  const { language } = useContext(AppContext);
  const paymentText = useLanguageText('payment');

  useEffect(() => {
    fetchData()
      .then((pay: any) => {
        setPayment(pay);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err.response);
      });
  }, []);

  if (loading) return <Loading />;

  if (!loading && !payment) return <Error error={404} />;

  const additions = extractPaymentType(payment, 'add');
  const deductions = extractPaymentType(payment, 'ded');
  const vb = extractPaymentType(payment, 'vb');
  const loans = extractPaymentType(payment, 'loan');
  const info = extractPaymentType(payment, 'info');
  const generalInfo0 = empInfo_0({ ...payment, emp });
  const generalInfo1 = empInfo_1({ ...payment, emp });

  return (
    <div className={`whole-container ${language}`}>
      <div className="container">
        <div className="header-container">
          <img alt="icon" className="logo" src={iconUrl} />
          <div className="title-container">
            <p className="title">{paymentText}</p>
            <p className="title">{payment.month[language]}</p>
            <div className="black-line" />
            <p className="subtitle">#{payment.id}</p>
          </div>
        </div>

        {/* -------------------------------- General Info ------------------------------- */}
        <div className="other-box" style={{ borderTop: 'none' }}>
          {/* Info 0 */}
          <ContentBox data={generalInfo0} />

          {/* Info 1 */}
          <ContentBox data={generalInfo1} />
        </div>

        {/* -------------------------------- Additions ------------------------------- */}
        <div className="other-box">
          {/* Additions */}
          <ContentBox numbers data={additions} />

          {/* Deductions */}
          <ContentBox numbers data={deductions} />
        </div>

        {/* -------------------------------- Total ------------------------------- */}
        <div className="other-box no-border">
          <div className="box" />
          <TotalBox additions={additions} deductions={deductions} total={payment.total} />
        </div>

        {/* -------------------------------- VB and Loans ------------------------------- */}
        {(vb || loans) && (
          <div className="other-box">
            {/* VB */}
            {vb && <ContentBox data={vb} />}

            {/* Loans */}
            {loans && <ContentBox data={loans} />}
          </div>
        )}

        {/* ------------------------------ Other Details ----------------------------- */}
        <SplitContentBox data={info} />
      </div>

      {/* ------------------------------ Download Link ----------------------------- */}
      <ButtonsContainer payment={payment} />
    </div>
  );
};

export default Main;
