import { useAppContext } from '../../context/AppContext';
import { useLanguageText } from '../../hooks/language';
import { ErrorPageError } from '../../types/errorTypes';
import Button from '../Button/Button';

const Error = ({ error }: ErrorPageError) => {
  const { setUser } = useAppContext();
  const logout = () => {
    localStorage.removeItem('access_token');
    setUser({});
  };
  return (
    <div className="loading">
      <b>{useLanguageText(`${error || 'error'}`)}</b>
      <Button text={useLanguageText('logout')} onClick={logout} />
    </div>
  );
};

export default Error;
