import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { PaymentDetails, PaymentValue } from '../../types/paymentTypes';
import { isObjectEmpty } from '../../utils/helpers';
import './SplitContentBox.scss';

const ContentBox = ({ data }: { data: PaymentDetails }) => {
  const { language } = useContext(AppContext);

  return data && !isObjectEmpty(data) ? (
    <div className="other-box">
      <div className="box">
        {data.values.map(
          (addition: PaymentValue, index: number) =>
            index < Math.ceil(data.values.length / 2) && (
              <div key={index} className="payments-row">
                <p>{addition.name[language]}</p>
                <p>{addition.value[language]}</p>
              </div>
            )
        )}
      </div>
      <div className="box">
        {data.values.map(
          (addition: PaymentValue, index: number) =>
            index > Math.floor(data.values.length / 2) && (
              <div key={index} className="payments-row">
                <p>{addition.name[language]}</p>
                <p>{addition.value[language]}</p>
              </div>
            )
        )}
      </div>
    </div>
  ) : null;
};

export default ContentBox;
