import { useEffect, useState } from 'react';
import { useLanguageText } from '../../hooks/language';
import './Loading.scss';

const Loading = () => {
  const [loading, setLoading] = useState(0);
  useEffect(() => {
    const timeout = setInterval(() => setLoading((loading) => (loading + 1) % 3), 500);
    return () => {
      clearTimeout(timeout);
    };
  });
  let loadingText = '';
  switch (loading) {
    case 0:
      loadingText = '.';
      break;
    case 1:
      loadingText = '..';
      break;
    case 2:
      loadingText = '...';
      break;
    default:
      loadingText = '...';
      break;
  }
  return (
    <div className="loading">
      <img alt="logo" className="loading-icon" src="images/ibs_logo.png" />
      <b>
        {useLanguageText('loading')}
        {loadingText}
      </b>
    </div>
  );
};

export default Loading;
