import { useLanguageText } from '../../hooks/language';
import { convertToCurrency } from '../../utils/helpers';

const TotalBox = ({ additions, deductions, total }: any) => {
  const [bankNet, totalText] = [useLanguageText('bankNet'), useLanguageText('total')];
  return (
    <div className="box no-border-box">
      <div className="payments-box extra-box">
        <p className="header">{totalText}</p>
        <div className="payments-row total">
          <p>{bankNet}</p>
          <p>{convertToCurrency(total)}</p>
        </div>
      </div>
    </div>
  );
};

export default TotalBox;
